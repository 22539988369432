// Relative Imports
import { Container, Section, Number, Text, Button } from "./styles";

const Error404 = () => {
  return (
    <Container>
      <Section>
        <Number>404</Number>
        <Text>Seek and you shall find everything...except this page.</Text>
        <Button to="/">Start Over</Button>
      </Section>
    </Container>
  );
};

export default Error404;
