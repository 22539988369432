// Library Imports
import React from "react";

// Relative Imports
import { Container, Checkboxes, Icon, Hide, Show, Label, Link } from "./styles";

const Radio = ({ className, checked, ...props }) => (
  <Container>
    <Label>
      I read and accept the{" "}
      <Link target="_blank" href="https://game7.io/terms">
        Terms & Conditions
      </Link>{" "}
      and{" "}
      <Link target="_blank" href="https://game7.io/privacy">
        Privacy
      </Link>{" "}
      policy.
    </Label>
    <Checkboxes>
      <Hide checked={checked} {...props} />
      <Show checked={checked}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </Show>
    </Checkboxes>
  </Container>
);

export default Radio;
