import { useEffect } from "react";
import Navigation from "../components/navigation";
import Footer from "../components/footer";
import Started from "../components/started/";
import Hero from "../components/hero-content/";

const XPEvent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navigation hideButton={true} />
      <Hero
        title="Join our nation"
        description="Enter to win a SteamDeck by following these steps"
        displayRightButton={true}
        buttons={true}
        rightButton="Join Discord"
        onClick={() => window.location.assign("https://discord.gg/wrKBGAGY")}
      />
      <Started
        button={true}
        disclaimer={true}
        firstTitle="Join Discord"
        firstDesciption="Enter our Nation's Town Square, introduce yourself, and showcase your magical skills."
        secondTitle="Complete Onboarding"
        secondDesciption="Embark on a quest for wisdom, uncover the secrets of Game7 Nation, and discover its hidden treasures."
        thirdTitle="Mint NFT"
        thirdDesciption="Mint your Citizen NFT, rise in status, and earn the respect of fellow citizens eagerly awaiting your arrival."
      />
      <Footer />
    </>
  );
};

export default XPEvent;
