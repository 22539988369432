// Relative Imports
import { Container, Wrapper } from "./styles";

const Grid = ({ children }) => {
  return (
    <Container>
      <Wrapper>{children}</Wrapper>
    </Container>
  );
};

export default Grid;
