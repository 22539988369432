// Relative Imports
import { Container, Text } from "./styles";

const TextColumn = ({ children }) => {
  return (
    <Container>
      <Text>{children}</Text>
    </Container>
  );
};

export default TextColumn;
