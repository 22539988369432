import { useEffect, useState } from "react";
import moment from "moment";
import Navigation from "../components/navigation";
import Hero from "../components/hero-content";
import MediaPanel from "../components/panel-brand";
import Footer from "../components/footer";
import Grid from "../components/grid";
import PressRelease from "../components/pressRelease";
import MediaRelease from "../components/mediaRelease";
import LoadingCard from "../components/loadingCard";
import Column from "../components/column";
import { Title } from "../constants/type.js";
import mediaArticles from "../hooks/mediaArticles.js";
import brand_assets from "../assets/images/brand.svg";
import pressReleases from "../hooks/pressReleases.js";

const Media = () => {
  // Media Details
  const [media, setMedia] = useState([]);
  const { getMedia } = mediaArticles();
  // Press Details
  const [press, setPress] = useState([]);
  const { getPress } = pressReleases();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getMedia().then((response) => setMedia(response));
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    getPress().then((response) => setPress(response));
    //eslint-disable-next-line
  }, []);

  const downloadFile = () => {
    window.location.href =
      "https://github.com/G7DAO/brand-kit/raw/main/Game7%20Brand%20Kit.zip";
  };

  const renderMediaArticles = () => {
    return media.map((m, i) => {
      return (
        <MediaRelease
          key={i}
          title={m.fields.title}
          description={m.fields.previewText}
          date={moment(m.fields.publishedDate).calendar()}
          url={m.fields.articleLink}
          img={m.fields.image.fields.file.url}
        />
      );
    });
  };

  const renderPressRelease = () => {
    return press.map((m, i) => {
      return (
        <PressRelease
          key={i}
          name={m.fields.pressTitle + `${" "}` + m.fields.pressSubtitle}
          description={m.fields.pressIntroduction}
          url={m.fields.pressUrl}
          date={moment(m.fields.pressPublishDate).calendar()}
          img={m.fields.pressCoverImage.fields.file.url}
          button="Read Release"
        />
      );
    });
  };

  return (
    <>
      <Navigation />
      <Hero title="Game7" subtitle="Media Assets" displayLeftButton="true" />
      <MediaPanel
        title="Brand Assets"
        description="Follow these guidelines when promoting Game7 in marketing communications, including advertising, articles, websites, and printed promotions."
        src={brand_assets}
        onClick={downloadFile}
      />
      <Column>
        <Title fontSize="100px" mb="40px">
          Media Coverage
        </Title>
        {media.length === 0 && (
          <Grid>
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
          </Grid>
        )}
        <Grid>{renderMediaArticles()}</Grid>
      </Column>
      {press.length === 0 && (
        <Column>
          <Title fontSize="100px" mb="40px">
            Press Releases
          </Title>
          <Grid>
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
          </Grid>
        </Column>
      )}

      {press.length !== 0 && (
        <Column>
          <Title fontSize="100px" mb="40px">
            Press Releases
          </Title>
          <Grid>{renderPressRelease()}</Grid>
        </Column>
      )}

      <Footer alert={true} />
    </>
  );
};

export default Media;
