import { createGlobalStyle } from "styled-components";
import Agharti from "../fonts/Agharti-Bold.woff";

export const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: 'Agharti';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url(${Agharti}) format('woff');

};

  body {
    padding: 0;
    margin: 0;
    border: 0;
    background: "#fafafa";
    display: flex;
    flex: 1;
    font-feature-settings: 'zero' 1;
    font-size: 16px;
    font-family: "Inter", "Bebas-Neue", sans-serif, -apple-system, BlinkMacSystemFont, "Fira Code", monospace;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-display: swap;
  }`;
