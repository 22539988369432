import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  width: 100vw;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1360px;
`;

export const Inner = styled.div`
  padding: 20px;
`;
