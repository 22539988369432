// Relative Imports
import {
  Container,
  Fill,
  Outline,
  Section,
  Title,
  Description,
  Heading,
  Text,
} from "./styles";

const Hero = ({ src, title, description }) => {
  const scrollPage = () => {
    window.scrollTo({
      top: "800",
      behavior: "smooth",
    });
  };

  return (
    <>
      <Container>
        <Text>
          <Title>{title}</Title>
          <Description>{description}</Description>
          <Section>
            <Outline to="/" onClick={scrollPage}>
              Learn More
            </Outline>
            <Fill to="/get-started">Join Now</Fill>
          </Section>
        </Text>
      </Container>
    </>
  );
};

export default Hero;
