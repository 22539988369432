import { useEffect } from "react";
import Navigation from "../components/navigation";
import Panel from "../components/panel-report";
import Footer from "../components/footer";
import Hero from "../components/hero-content";

import economicDesign from "../assets/report/economicDesign.png";
import chainSelection from "../assets/report/chainSelection.png";
import distribution from "../assets/report/distribution.png";
import marketplace from "../assets/report/marketplace.png";
import onChainOffchain from "../assets/report/onChainOffchain.png";
import smartContract from "../assets/report/smartContract.png";
import walletsSdks from "../assets/report/walletsSdks.png";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navigation />
      <Hero
        title="Game7"
        subtitle="2022 Game Dev Report"
        buttons={true}
        displayRightButton={true}
        rightButton="Read Full Report"
        onClick={() =>
          window.location.assign(
            "https://cdn.game7.io/reports/Game-Developer-Report-by-Game7.pdf"
          )
        }
      />
      <Panel
        link={""}
        src={chainSelection}
        heading="Takeaway #1"
        title="Chain Selection"
        description="The chain selection process can be very political and multifaceted. Most networks are incentivized to restrict user adoption into their own ecosystem which can push the industry away from interoperability. While there isn’t a perfect chain for everyone, we’ve outlined the major pros and cons of certain chains in the Ecosystem Specific section of the report. "
      />
      <Panel
        link={""}
        reverse={true}
        src={smartContract}
        heading="Takeaway #2"
        title="Smart Contract Development"
        description="Existing smart contract and NFT standards across every ecosystem aren’t optimized for game development. ERC1155 NFT standard is lacking adoption compared to ERC721. Diamond Standard (EIP-2535) is consistently being used by experienced developers to build smart contracts."
      />
      <Panel
        link={""}
        src={walletsSdks}
        heading="Takeaway #3"
        title="Wallets and SDKs"
        description="Game devs have a divergent and polarized opinion when it comes to the decision of holding custody over users’ wallets and assets to offer a better user experience. Native in-game web3 integration for popular game engines, such as Unity and Unreal Engine, is in dire need."
      />
      <Panel
        link={""}
        reverse={true}
        src={marketplace}
        heading="Takeaway #4"
        title="Marketplaces"
        description="Most of the mainstream wallets and marketplaces weren’t built for games and put more focus towards PFPs (Profile Pictures) and Art NFTs. This compels many gamedevs to want to build their own in-game marketplaces."
      />
      <Panel
        link={""}
        src={onChainOffchain}
        heading="Takeaway #5"
        title="On-chain vs Off-chain"
        description="User experience is a much higher priority than the level of decentralization for both game developers and gamers; most web3 game mechanics remain off-chain. Determining which portions of the game experience are on-chain and synchronizing this data is a struggle."
      />
      <Panel
        link={""}
        reverse={true}
        src={economicDesign}
        heading="Takeaway #6"
        title="Economic Design"
        description="There isn’t a proven working model for Economic Design, and naturally, there is no formula that applies to all. Many game developers are just “winging it” here through a trial and error approach. Both game developers and guilds themselves are trying to move away from the play-to-earn (P2E) model due to sustainability issues."
      />
      <Panel
        link={""}
        src={distribution}
        heading="Takeaway #7"
        title="Distribution and Communities"
        description="Game discovery and distribution is a gap across all platforms (browser, desktop, and mobile). Managing sustainable gaming communities is challenging, as most users focus more on the price of in-game tokens and assets (NFTs) than the gameplay itself."
      />
      <Footer />
    </>
  );
};

export default Home;
