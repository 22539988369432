// Relative Imports
import { Column, Container, Copyright, Game7, Grid, Route, Section, Title, Window } from './styles'

import brand from '../../assets/brand/brand.svg'

const Footer = ({ alert }) => {
  return (
    <Container alert={alert}>
      <Column>
        <Grid>
          <Section>
            <Title>Social</Title>
            <Window href='https://twitter.com/G7_DAO' rel='noreferrer' target='_blank'>
              Twitter
            </Window>
            <Window href='https://github.com/G7DAO' rel='noreferrer' target='_blank'>
              Github
            </Window>
            <Window href='https://discord.gg/g7dao' rel='noreferrer' target='_blank'>
              Discord
            </Window>
            <Window href='https://medium.com/@G7_DAO' rel='noreferrer' target='_blank'>
              Medium
            </Window>

            <Window href='https://forum.game7.io' rel='noreferrer' target='_blank'>
              Forum
            </Window>
          </Section>

          <Section>
            <Title>About</Title>
            <Route to='/media'>Media</Route>
            <Route to='/brand'>Brand</Route>
            <Route to='/newsletter'>Newsletter</Route>
            <Route to='/blog'>Blog</Route>
          </Section>
          <Section>
            <Title>Initiatives</Title>
            <Route to='/dev-report-2022'>Dev Report 2022</Route>

            <Window
              href='https://discourse.bitdao.io/t/g7p-1-game7-bootstrapping-phase/2836'
              rel='noreferrer'
              target='_blank'
            >
              BitDAO Proposal
            </Window>
            <Window href='https://grants.game7.io' rel='noreferrer' target='_blank'>
              Grants Program
            </Window>
            <Window href='https://gam3r.org' rel='noreferrer' target='_blank'>
              Gam3r Forum
            </Window>
          </Section>

          <Section>
            <Title>Legal</Title>
            <Route to='/terms'>Terms</Route>
            <Route to='/privacy'>Privacy</Route>
            <Route to='/cookies'>Cookies</Route>
          </Section>
        </Grid>
        <Copyright>
          <Game7 src={brand} />
        </Copyright>
      </Column>
    </Container>
  )
}

export default Footer
