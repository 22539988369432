import styled from "styled-components";
import media from "../../constants/media.js";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  wdith: 100vw;
  background: ${(props) => props.theme.foreground};
`;
export const Wrapper = styled.div`
  display: grid;
  justify-content: flex-start;
  grid-gap: 20px;
  row-gap: 20px;
  width: 100%;
  max-width: 1680px;

  ${media.sm`
    display: flex;
    flex-direction: column;
  `}

  ${media.md`
    display: flex;
    flex-direction: column;
  `}

  ${media.lg`
    grid-template-columns: 1fr 1fr 1fr;
  `}

  ${media.xxl`
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `}
`;
