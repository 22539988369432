import styled from "styled-components";
import media from "../../constants/media.js";

export const Container = styled.div`
  height: auto;
  width: 100%;

  ${media.sm`
    width: 100%;
  `}
`;
export const Content = styled.div`
  margin: 20px;
`;
