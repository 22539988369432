import Navigation from "../components/navigation";
import Footer from "../components/footer";
import Error404 from "../components/error404/";

const Error = () => {
  return (
    <>
      <Navigation />
      <Error404 />
      <Footer />
    </>
  );
};

export default Error;
