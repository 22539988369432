// Relative Imports
import {
  Container,
  Title,
  Image,
  Content,
  Route,
  Description,
  Top,
  Inner,
} from "./styles";
import { H6 } from "../../constants/type.js";

const PressRelease = ({
  name,
  date,
  description,
  disabled,
  height,
  url,
  img,
  button,
  onClick,
}) => {
  return (
    <Container>
      <Image src={img} />
      <Inner>
        <Top>
          <Content>
            <Title>{name}</Title>
            <H6 mb="16px" mt="8px">
              {date}
            </H6>
            <Description>{description}</Description>
          </Content>
        </Top>
        <Route onClick={onClick} to={url}>
          {button}
        </Route>
      </Inner>
    </Container>
  );
};

export default PressRelease;
