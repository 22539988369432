// Relative Imports
import { Container, Button } from "./styles";
import { H1 } from "../../constants/type.js";

const ButtonRow = ({ onClick }) => {
  return (
    <Container>
      <H1>Ready to get started?</H1>
      <Button target="_blank" href="https://discord.gg/wrKBGAGY">
        Join Discord
      </Button>
    </Container>
  );
};

export default ButtonRow;
