import styled from "styled-components";

export const Container = styled.label`
  display: flex;
  padding-top: 4px;
  margin-left: 4px;
  margin-right: 4px;
  height: 32px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Checkboxes = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export const Label = styled.div`
  font-size: 13px;
  margin-top: -8px;
  font-family: "Inter", sans-serif;
  color: ${(props) => props.theme.secondary};
`;

export const Link = styled.a`
  font-size: 13px;
  font-weight: bold;
  color: ${(props) => props.theme.secondary};
  transition: 750ms;
  font-family: "Inter", sans-serif;

  &:hover {
    color: ${(props) => props.theme.accent};
    transition: 750ms;
  }
`;

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

export const Hide = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;

  &:hover {
    cursor: pointer;
`;

export const Show = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${(props) =>
    props.checked ? props.theme.accent : props.theme.foreground};
  border-radius: 3px;
  border: 2px solid ${(props) => props.theme.accent};
  transition: all 150ms;

  &:hover {
    cursor: pointer;

  ${Hide}:focus + & {
    box-shadow: 0 0 0 1px ${(props) => props.theme.accent};
  }

  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;
