// Relative Imports
import { Container, Route } from "./styles";

const Alert = () => {
  return (
    <Container>
      👉
      <Route to="/get-started">Now Live: Join Game7 Nation!</Route>
      👈
    </Container>
  );
};

export default Alert;
