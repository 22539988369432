import { createClient } from "contentful";
import moment from "moment";

const singleBlog = () => {
  const client = createClient({
    space: process.env.REACT_APP_SPACE_ID,
    accessToken: process.env.REACT_APP_ACCESS_TOKEN,
  });

  const getBlog = async ({ path }) => {
    try {
      const entries = await client.getEntries({
        content_type: "contentBlog",
      });

      const result = entries.items.map((e, i) => {
        if (path === e.fields.blogUrl) {
          const index = i;
          const title = e.fields.blogTitle;
          const subtitle = e.fields.blogSubtitle;
          const date = moment(e.fields.blogPublishDate).calendar();
          const description = e.fields.blogBody;
          const object = e;

          return { title, index, date, subtitle, description, object };
        }
        return null;
      });
      return result;
    } catch (error) {
      console.log(error);
    }
  };
  return { getBlog };
};

export default singleBlog;
