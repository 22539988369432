// Relative Imports
import {
  Container,
  Content,
  Fill,
  Title,
  Subtitle,
  Description,
  Outline,
  Section,
  Date,
} from "./styles";
import { scrollPage } from "../../utils/utils.js";
import hero from "../../assets/patterns/hero.svg";

const HeroContent = ({
  title,
  subtitle,
  date,
  description,
  displayLeftButton,
  displayRightButton,
  rightButton,
  onClick,
  backgroundImage,
  buttons,
}) => {
  return (
    <Container backgroundImage={hero}>
      <Content>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        <Description>{description}</Description>
        {!date ? null : <Date>{date}</Date>}
        {buttons && (
          <Section>
            {displayLeftButton && (
              <Outline onClick={() => scrollPage()}>Learn More</Outline>
            )}
            {displayRightButton && <Fill onClick={onClick}>{rightButton}</Fill>}
          </Section>
        )}
      </Content>
    </Container>
  );
};

export default HeroContent;
