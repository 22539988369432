import { createClient } from "contentful";

const mediaArticles = () => {
  const client = createClient({
    space: process.env.REACT_APP_SPACE_ID,
    accessToken: process.env.REACT_APP_ACCESS_TOKEN,
  });

  const getMedia = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "mediaArticles",
      });

      return entries.items;
    } catch (error) {
      console.log(error);
    }
  };
  return { getMedia };
};

export default mediaArticles;
