// Relative Imports
import { Container, Center } from "./styles";

const Column = ({ paddingTop, paddingBottom, children }) => {
  return (
    <Container paddingTop={paddingTop} paddingBottom={paddingBottom}>
      <Center>{children}</Center>
    </Container>
  );
};

export default Column;
