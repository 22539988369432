import styled from "styled-components";
import form from "../../assets/images/form.svg";

export const Container = styled.div`
  height: 100%;
  width: 100%;
`;

export const Image = styled.div`
  height: 220px;
  width: 100%;
  margin-bottom: 20px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  background-image: url(${form});
`;

export const Title = styled.div`
  font-family: "Bebas Neue", sans-serif;
  font-size: 32px;
  color: ${(props) => props.theme.primary};
  text-align: center;
`;

export const Description = styled.div`
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 1.5em;
  color: ${(props) => props.theme.secondary};
  text-align: center;
`;
