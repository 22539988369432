import styled from "styled-components";
import media from "../../constants/media.js";
import { Link } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: 100px 0px;
  width: 100%;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 1360px;
  align-items: center;

  ${media.md`
    grid-template-columns: 1fr;
  `};
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

export const Image = styled.img`
  width: 100%;
  margin-right: -40px;

  ${media.md`
    display: flex;
    flex-order: -1;
  `};
`;

export const Copy = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  width: auto;
  font-family: "Inter", sans-serif;

  ${media.md`
    order: -1;
  `};
`;

export const Title = styled.div`
  font-family: "Agharti", sans-serif;
  text-transform: uppercase;
  font-size: 100px;
  color: ${(props) => props.theme.primary};
  line-height: 1.1em;

  ${media.md`
    font-size: 72px;
  `}
`;

export const Description = styled.div`
  font-family: "Inter", sans-serif;
  font-size: 18px;
  line-height: 1.5em;
  color: ${(props) => props.theme.secondary};

  ${media.md`
    font-size: 16px;
  `};
`;

export const Heading = styled.div`
  font-size: 30px;
  color: ${(props) => props.theme.secondary};
  font-family: "Agharti", sans-serif;
  text-transform: uppercase;
  text-align: left;
`;

export const Route = styled(Link)`
  font-family: "Inter";
  font-size: 18px;
  line-height: 1.5em;
  color: ${(props) => props.theme.accent};
  text-decoration: none;
  padding-top: 16px;

  ${media.md`
    font-size: 16px;
  `}
`;

export const Row = styled.div`
  display: flex;
  grid-gap: 20px;
  padding-top: 20px;

  ${media.sm`
    flex-direction: column;
  `};
`;

export const Download = styled.a`
  font-size: 16px;
  background: ${(props) => props.theme.accent};
  font-family: "Inter", sans-serif;
  text-align: center;
  color: white;
  border: none;
  padding: 20px 24px;
  width: 200px;
  border-radius: 8px;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }

  ${media.sm`
    width: auto;
  `};
`;

export const Notion = styled.a`
  font-size: 16px;
  background: white;
  border: 1px solid ${(props) => props.theme.accent};
  font-family: "Inter", sans-serif;
  text-align: center;
  color: ${(props) => props.theme.accent};
  padding: 20px 24px;
  width: 200px;
  border-radius: 8px;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }

  ${media.sm`
    width: auto;
  `};
`;
