import {
  P,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  UL,
  OL,
  LI,
  A,
  Quote,
  Table,
  Embeded,
} from "../constants/type.js";
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";

export const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <strong key={`${text}-key`}>{text}</strong>,
    [MARKS.ITALIC]: (text) => <em key={`${text}-key`}>{text}</em>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <P>{children}</P>,
    [BLOCKS.QUOTE]: (node, children) => <Quote>{children}</Quote>,
    [BLOCKS.HEADING_1]: (node, children) => <H1>{children}</H1>,
    [BLOCKS.HEADING_2]: (node, children) => <H2>{children}</H2>,
    [BLOCKS.HEADING_3]: (node, children) => <H3>{children}</H3>,
    [BLOCKS.HEADING_4]: (node, children) => <H4>{children}</H4>,
    [BLOCKS.HEADING_5]: (node, children) => <H5>{children}</H5>,
    [BLOCKS.HEADING_6]: (node, children) => <H6>{children}</H6>,
    [BLOCKS.OL_LIST]: (node, children) => <OL>{children}</OL>,
    [BLOCKS.UL_LIST]: (node, children) => <UL>{children}</UL>,
    [BLOCKS.LIST_ITEM]: (node, children) => <LI>{children}</LI>,
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
      <Embeded
        src={node.data?.target?.fields?.file?.url}
        alt={node.data?.target?.fields?.title}
      />
    ),
    [INLINES.HYPERLINK]: ({ data }, children) => (
      <A href={data.uri}>{children}</A>
    ),
    [BLOCKS.TABLE]: (node, children) => (
      <Table>
        <thead></thead>
        <tbody>{children}</tbody>
      </Table>
    ),
  },
  renderText: (text) => {
    return text.split("\n").reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  },
};
