// Relative Imports
import { Container, Field, Row, Error, Label } from "./styles";

const Input = ({ label, error, placeholder, onChange, name, value }) => {
  return (
    <Container>
      <Row>
        <Label>{label}</Label>
        <Error>{error}</Error>
      </Row>
      <Field
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </Container>
  );
};

export default Input;
