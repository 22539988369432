import Airtable from "airtable";
import validator from "validator";
import { useEffect, useState } from "react";
import moment from "moment";

import Navigation from "../components/navigation";
import Footer from "../components/footer";
import Alert from "../components/alert";
import Hero from "../components/hero-content";
import Modal from "../components/modal";
import Input from "../components/_forms/input";
import Splitter from "../components/_forms/splitter";
import newsletterArticles from "../hooks/newsletter/newsletterArticles.js";
import PressRelease from "../components/pressRelease";
import LoadingCard from "../components/loadingCard";
import Grid from "../components/grid";
import Column from "../components/column";
import Radio from "../components/_forms/radio/index.js";
import Confirmation from "../components/confirmation/index.js";
import { refCodeGenerator } from "../utils/utils.js";

const Newsletter = () => {
  const [show, showModal] = useState(false);
  const [loading, isLoading] = useState();
  const [emailError, setEmailError] = useState("");
  const [emailValid, emailIsValid] = useState(false);
  const [newsletter, setNewsletter] = useState([]);
  const { getNewsletters } = newsletterArticles();
  const [accepted, acceptTerms] = useState(false);
  const [step, increment] = useState(1);
  const [tempRef, setTempRef] = useState("");

  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    leadLocation: "newsletter",
    refCode: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    isLoading(false);
  }, []);

  useEffect(() => {
    getNewsletters().then((response) => setNewsletter(response));
    //eslint-disable-next-line
  }, []);

  const incrementStep = () => {
    isLoading(true);
    const timer = setTimeout(() => {
      isLoading(false);
      increment(step + 1);
    }, 1000);
    return () => clearTimeout(timer);
  };

  const renderNewsletters = () => {
    return newsletter.map((m, i) => {
      return (
        <PressRelease
          key={i}
          name={m.fields.newsletterTitle + " " + m.fields.newsletterSubtitle}
          date={moment(m.fields.newsletterPublishDate).calendar()}
          url={m.fields.newsletterUrl}
          button="Read Newsletter"
          img={m.fields.newsletterCoverImage.fields.file.url}
        />
      );
    });
  };

  const base = new Airtable({
    apiKey: process.env.REACT_APP_AIRTABLE_NEWSLETTER_API,
  }).base(process.env.REACT_APP_AIRTABLE_BASE);

  const handleInputChange = (event) => {
    if (event.target.name === "emailAddress") {
      validateEmail(event.target.value);
      setState((prevProps) => ({
        ...prevProps,
        [event.target.name]: event.target.value,
      }));
    } else {
      setState((prevProps) => ({
        ...prevProps,
        [event.target.name]: event.target.value,
      }));
    }
  };

  const acceptAndGenerateRefCode = () => {
    const updateForm = (e) => {
      const { firstName, lastName } = state;
      setState((prevState) => {
        return {
          ...prevState,
          refCode: refCodeGenerator("newsletter", firstName, lastName),
        };
      });
    };

    updateForm();
    acceptTerms(!accepted);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setTempRef(state.refCode);
    isLoading(true);
    base("Website").create([
      {
        fields: {
          firstName: state.firstName,
          lastName: state.lastName,
          emailAddress: state.emailAddress,
          leadLocation: state.leadLocation,
          refCode: state.refCode,
        },
      },
    ]);

    const timer = setTimeout(() => {
      isLoading(false);
      increment(step + 1);
      acceptTerms(false);
      setState({
        firstName: "",
        lastName: "",
        emailAddress: "",
        leadLocation: "newsletter",
      });
    }, 1000);

    return () => clearTimeout(timer);
  };

  const validateEmail = (email) => {
    if (validator.isEmail(email) && email.length > 1) {
      emailIsValid(true);
      setEmailError("");
    } else {
      emailIsValid(false);
      setEmailError("Enter valid email");
    }
  };

  const closeModal = () => {
    showModal(false);
    increment(1);
    setState({
      firstName: "",
      lastName: "",
      emailAddress: "",
      leadLocation: "newsletter",
    });

    if (typeof window != "undefined" && window.document) {
      document.body.style.overflow = "scroll";
    }
  };

  // Validate Step One
  const firstName = state.firstName !== "";
  const lastName = state.lastName !== "";
  const leadLocation = state.leadLocation !== "";

  const validateFirstStep =
    firstName && lastName && emailValid && leadLocation && accepted;

  const checkSteps = () => {
    if (step === 1) {
      return !validateFirstStep;
    }
    return null;
  };

  return (
    <>
      <Navigation />
      {show && (
        <Modal
          title="Newsletter Registration"
          label="Next"
          step={step}
          close={closeModal}
          submit={handleSubmit}
          loading={loading}
          disabled={checkSteps()}
        >
          {step === 1 && (
            <>
              <Splitter>
                <Input
                  name="firstName"
                  value={state.firstName}
                  onChange={handleInputChange}
                  label="First Name"
                  placeholder="Enter your first name"
                />
                <Input
                  name="lastName"
                  value={state.lastName}
                  onChange={handleInputChange}
                  label="Last Name"
                  placeholder="Enter your last name"
                />
              </Splitter>
              <Input
                error={emailError}
                name="emailAddress"
                value={state.emailAddress}
                onChange={handleInputChange}
                label="Email"
                placeholder="Enter your email"
              />
              <Radio
                onChange={() => acceptAndGenerateRefCode()}
                checked={accepted}
              />
            </>
          )}
          {step === 2 && (
            <>
              <Confirmation referral={tempRef} />
            </>
          )}
        </Modal>
      )}

      <Hero
        title="Game7"
        subtitle="Newsletter"
        buttons={true}
        displayRightButton={true}
        rightButton="Subscribe"
        onClick={() => showModal(true)}
      />
      <Column>
        {newsletter.length === 0 && (
          <Grid>
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
          </Grid>
        )}
        <Grid>{renderNewsletters()}</Grid>
      </Column>

      <Alert />
      <Footer alert={true} />
    </>
  );
};

export default Newsletter;
