import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  height: 42px;
  background: ${(props) => props.theme.accent};
  font-family: "Inter", sans-serif;
  z-index: 3000;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
`;

export const Route = styled(Link)`
  font-weight: 600;
  color: #fff;
  font-size: 14px;
  text-decoration: underline;
  padding: 0px 12px;

  &:hover {
    cursor: pointer;
  }
`;
