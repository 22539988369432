import styled from "styled-components";

export const Container = styled.div`
  padding: 20px 0px;
  width: auto;
  display: flex;
  align-items: center;
  margin-top: -60px;
  flex-direction: column;
`;

export const Button = styled.a`
  height: 60px;
  width: 200px;
  margin-top: 40px;
  display: flex;
  font-family: "Inter", sans-serif;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: white;
  background: ${(props) => props.theme.accent};

  border-radius: 8px;
  text-decoration: none;
  transition: 750ms;

  &:hover {
    transition: 750ms;
    cursor: pointer;
  }
`;
