// Relative Imports
import {
  Container,
  Title,
  Image,
  Content,
  Route,
  Description,
  Top,
} from "./styles";
import { H6 } from "../../constants/type.js";

const MediaRelease = ({
  title,
  date,
  description,
  disabled,
  height,
  url,
  img,
}) => {
  return (
    <Container>
      <Top>
        <Image height={height} src={img} />
        <Content>
          <Title>{title}</Title>
          <H6 mb="16px" mt="8px">
            {date}
          </H6>
          <Description>{description}</Description>
        </Content>
      </Top>
      <Route href={url} target="_blank">
        Read Article
      </Route>
    </Container>
  );
};

export default MediaRelease;
