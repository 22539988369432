// Relative Imports
import {
  Container,
  Content,
  Image,
  Copy,
  Heading,
  Title,
  Description,
  Download,
  ImageContainer,
  Row,
} from "./styles";

const PanelReport = ({
  title,
  description,
  reverse,
  link,
  show,
  src,
  heading,
}) => {
  return (
    <Container>
      <Content>
        {!reverse ? (
          <>
            <Copy>
              <Heading>{heading}</Heading>
              <Title>{title}</Title>
              <Description>{description}</Description>
              <Row>
                <Download
                  target="_blank"
                  rel="noreferrer"
                  href="https://cdn.game7.io/reports/Game-Developer-Report-by-Game7.pdf"
                >
                  Read Full Report
                </Download>
              </Row>
            </Copy>
            <ImageContainer>
              <Image align="right" src={src} />
            </ImageContainer>
          </>
        ) : (
          <>
            <ImageContainer>
              <Image reverse src={src} />
            </ImageContainer>
            <Copy reverse>
              <Heading>{heading}</Heading>
              <Title>{title}</Title>
              <Description>{description}</Description>
              <Row>
                <Download
                  target="_blank"
                  rel="noreferrer"
                  href="https://cdn.game7.io/reports/Game-Developer-Report-by-Game7.pdf"
                >
                  Read Full Report
                </Download>
              </Row>
            </Copy>
          </>
        )}
      </Content>
    </Container>
  );
};

export default PanelReport;
