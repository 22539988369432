// Relative Imports
import { Container, Content } from "./styles";

const TextPage = ({ title, date, doc, content }) => {
  return (
    <Container>
      <Content>{content}</Content>
    </Container>
  );
};

export default TextPage;
