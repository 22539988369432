import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding-top: ${(props) => props.paddingTop};
  padding-bottom: ${(props) => props.paddingBottom};
  background: ${(props) => props.theme.foreground};
`;

export const Center = styled.div`
  height: 100%;
  width: 100%;
  grid-gap: 20px;
  max-width: 1360px;
  padding: 20px;
`;
