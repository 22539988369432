import { useEffect } from "react";
import Navigation from "../components/navigation";
import Footer from "../components/footer";
import Started from "../components/started/";
import Hero from "../components/hero-content/";
import ButtonRowNormal from "../components/buttonRowNormal/index.js";

const Start = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navigation />
      <Hero
        title="Getting Started"
        displayRightButton={true}
        buttons={true}
        rightButton="Join Discord"
        onClick={() => window.location.assign("https://discord.gg/g7dao")}
      />
      <Started
        firstTitle="Join Discord"
        firstDesciption="Enter our Nation's Town Square, introduce yourself, and showcase your magical skills."
        secondTitle="Complete Onboarding"
        secondDesciption="Embark on a quest for wisdom, uncover the secrets of Game7 Nation, and discover its hidden treasures."
        thirdTitle="Mint NFT"
        thirdDesciption="Mint your Citizen NFT, rise in status, and earn the respect of fellow citizens eagerly awaiting your arrival."
      />
      <ButtonRowNormal />
      <Footer />
    </>
  );
};

export default Start;
