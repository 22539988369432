import styled from "styled-components";
import media from "../../constants/media.js";
import { Link } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: 100px 0px;
  width: 100%;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 1360px;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 80px;

  ${media.md`
    grid-template-columns: 1fr;
  `};
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

export const Image = styled.img`
  max-width: 500px;
  max-height: 600px;

  ${media.md`
    display: flex;
    flex-order: -1;
      width: 100%;
  `};
`;

export const Copy = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  width: auto;
  font-family: "Inter", sans-serif;

  ${media.md`
    order: -1;
  `};
`;

export const Title = styled.div`
  font-family: "Agharti", sans-serif;
  font-size: 100px;
  margin-bottom: 12px;
  color: ${(props) => props.theme.primary};
  line-height: 1.1em;
  text-transform: uppercase;

  ${media.md`
    font-size: 72px;
  `}
`;

export const Description = styled.div`
  font-family: "Inter", sans-serif;
  font-size: 18px;
  line-height: 1.5em;
  color: ${(props) => props.theme.secondary};

  ${media.md`
    font-size: 16px;
  `};
`;

export const Heading = styled.div`
  font-size: 30px;
  color: ${(props) => props.theme.secondary};
  font-family: "Agharti", sans-serif;
  text-align: left;
`;

export const Route = styled(Link)`
  height: 60px;
  width: 220px;
  display: flex;
  font-family: "Inter", sans-serif;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: white;
  background: ${(props) => props.theme.accent};
  border-radius: 8px;
  text-decoration: none;
  transition: 750ms;
  margin-top: 20px;

  ${media.md`
    width: 200px;
`}

  &:hover {
    transition: 750ms;
    cursor: pointer;
  }
`;

export const Fill = styled.a`
  height: 60px;
  width: 220px;
  display: flex;
  font-family: "Inter", sans-serif;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: white;
  background: ${(props) => props.theme.accent};
  border-radius: 8px;
  text-decoration: none;
  transition: 750ms;
  margin-top: 20px;

  ${media.md`
      width: 200px;
  `}

  &:hover {
    transition: 750ms;
    cursor: pointer;
  }
`;

// export const Background = styled.div`
//   height: 420px;
//   width: 100%;
//   color: #cacaca;
//
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   font-family: "Bebas Neue";
//   flex-order: ${(props) => (props.reverse ? 1 : 2)};
//   /* background-image: url(${confetti}); */
//   background-size: cover;
//   /* animation-name: ${breatheAnimation}; */
//   animation-duration: 2s;
//   animation-iteration-count: infinite;
//   animation-direction: alternate-reverse;
//   align-self: center;
//
//   ${media.md`
//     width: 100%;
//   `};
// `;
