import { createClient } from "contentful";

const newsletterArticles = () => {
  const client = createClient({
    space: process.env.REACT_APP_SPACE_ID,
    accessToken: process.env.REACT_APP_ACCESS_TOKEN,
  });

  const getNewsletters = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "contentNewsletter",
      });

      return entries.items;
    } catch (error) {
      console.log(error);
    }
  };
  return { getNewsletters };
};

export default newsletterArticles;
