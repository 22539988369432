import { useEffect } from "react";
import Navigation from "../components/navigation";
import Hero from "../components/hero-content";
import Panel from "../components/panel-assets";
import Footer from "../components/footer";
import logo from "../assets/media/logo.svg";
import monogram from "../assets/media/monogram.svg";
import illegal from "../assets/media/illegal.svg";

const Brand = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const downloadFile = () => {
    window.location.href =
      "https://github.com/G7DAO/brand-kit/raw/main/Game7%20Brand%20Kit.zip";
  };
  return (
    <>
      <Navigation />
      <Hero
        title="Game7"
        subtitle="Brand Assets"
        buttons="true"
        displayRightButton="true"
        rightButton="Download Assets"
        onClick={downloadFile}
      />
      <Panel
        title="Primary Mark"
        description="For most applications, use a simple black mark over a solid background or a white mark over a solid dark background. The Original Game7 Long Version Logo can be used as well, if the background is appropriate and does not distort the logo in an way."
        src={logo}
        button={true}
      />
      <Panel
        title="Monogram"
        description="The Monogram can be used when our primary mark is too large for an application. It is also regularly used on Social Media Assets."
        src={monogram}
        reverse={true}
        button={true}
      />
      <Panel
        title="Do's & Dont's"
        description={
          <>
            <div>
              Always keep a respected distance between the Logo and any assets
              that you are willing to place near it - Use our unique symbol as
              the initial guide to decide appropriate spacing between our
              Logotype and other assets.
            </div>
            <ul>
              <li>Do not skew/transform/rotate/warp the logo.</li>
              <li>Do not add drop shadow, glow or any other effect on logo.</li>
              <li>No opacity changes on logo.</li>
              <li>
                No colors allowed that are not related to the color scheme
                mentioned in this document.
              </li>
              <li>No other font can be used to type out “GAME7”.</li>
            </ul>
          </>
        }
        src={illegal}
        button={true}
      />

      <Footer alert={true} />
    </>
  );
};

export default Brand;
