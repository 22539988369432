import { createGlobalStyle } from "styled-components";
import Agharti from "../fonts/Agharti-Bold.woff";

export const FontStyles = createGlobalStyle`
  @font-face {
    font-family: 'Agharti-Bold', sans-serif;
    src: url(${Agharti}) format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: auto;
  };

  `;
