// Relative Imports
import {
  Container,
  Content,
  Image,
  Copy,
  Heading,
  Title,
  Route,
  Description,
  ImageContainer,
  Fill,
} from "./styles";

const MediaPanel = ({
  title,
  description,
  reverse,
  link,
  show,
  src,
  heading,
  button,
  onClick,
}) => {
  return (
    <Container>
      <Content>
        {!reverse ? (
          <>
            <Copy>
              <Heading>{heading}</Heading>
              <Title>{title}</Title>
              <Description>{description}</Description>
              <Fill onClick={onClick}>Download Assets</Fill>
            </Copy>
            <ImageContainer>
              <Image align="right" src={src} />
            </ImageContainer>
          </>
        ) : (
          <>
            <ImageContainer>
              <Image reverse src={src} />
            </ImageContainer>
            <Copy reverse>
              <Heading>{heading}</Heading>
              <Title>{title}</Title>
              <Description>{description}</Description>
              <Fill onClick={onClick}>Download Assets</Fill>
            </Copy>
          </>
        )}
      </Content>
    </Container>
  );
};

export default MediaPanel;
