import styled from "styled-components";
import media from "../../constants/media.js";
import { Link } from "react-router-dom";

export const Container = styled.div`
  width: 100vw;
  height: 90vh;
  background: ${(props) => props.theme.background};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;
export const Number = styled.div`
  font-size: 404px;
  font-family: "Bebas Neue", sans-serif;
  color: ${(props) => props.theme.primary};
  margin: 0;

  ${media.md`
      font-size: 202px;
  `};
`;

export const Text = styled.div`
  font-size: 40px;
  margin-top: -40px;
  font-family: "Bebas Neue", sans-serif;
  color: ${(props) => props.theme.secondary};
  text-align: center;

  ${media.md`
    font-size: 32px;
`};
`;

export const Button = styled(Link)`
  margin-top: 20px;
  font-family: "Inter";
  font-size: 18px;
  width: 180px;
  line-height: 1.5em;
  color: #ffffff;
  text-decoration: none;
  padding: 16px 20px;
  border-radius: 8px;
  text-align: center;
  background: ${(props) => props.theme.accent};

  ${media.md`
    font-size: 16px;
  `}
`;
