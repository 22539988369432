
import styled from "styled-components";
import Footer from "../components/footer";
import Navigation from "../components/navigation";

const Main = styled.main`
    padding-top: 60px;
    width: 100vw;
    padding-bottom: 50px;

    a {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        line-height: 1.5em;
        text-decoration: none;
        color: ${(props) => props.theme.secondary};
        transition: 500ms;
        height: 32px;
        align-items: center;
        display: flex;
        text-align: ${(props) => props.align};

        &:hover {
            color: ${(props) => props.theme.accent};
            transition: 500ms;
            cursor: pointer;
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        font-family: var(--body-font-family);
        /* width: 100%; */
        max-width: 600px;
    }


    main {
        display: grid;
        grid-gap: var(--spacing-5);
        gap: var(--spacing-5);
        justify-items: center;
        align-items: center;
        grid-auto-rows: min-content;
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
        padding: 20px;
        padding-top: 60px;
        box-sizing: border-box;
    }
`

const Gamejam = () => {

    return (
        <>
            <Navigation />

            <Main>
                <main className="game7-page_game7-page__alSYE">
                    <h2 style={{ textAlign: 'center' }}>Game7 Showcase Announcement</h2>
                    <img alt="Game7 logo." srcSet="https://www.rensa.games/_next/static/media/game7-custom-lockup.50d8aa5e.svg 1x, https://www.rensa.games/_next/static/media/game7-custom-lockup.50d8aa5e.svg 2x" src="https://www.rensa.games/_next/static/media/game7-custom-lockup.50d8aa5e.svg" width={300} height={62} decoding="async" data-nimg={1} loading="lazy" style={{ color: 'transparent' }} />
                    <p className="game7-page_game7-page__copy-lead__4MicW">We are excited to host Game7's <b>first-ever game jam</b> on our
                        Showcase platform! This event is a unique opportunity for both traditional and blockchain game developers to
                        collaborate in a new way.</p>
                    <h2 style={{ textAlign: 'center' }}>❗REGISTER TODAY❗</h2>
                    <p className="game7-page_game7-page__copy-big__ZwT66" style={{ }}>🚀 Sign up for the Showcase and <a className="game7-page_game7-page__copy-link__RRbWL" href="https://www.rensa.games/showcase/game7-game-jam" target="_blank" rel="noreferrer">Create Your Team</a><br />📚 Learn more with Rensa's <a className="game7-page_game7-page__copy-link__RRbWL" href="https://docs.rensa.games/showcase" target="_blank" rel="noreferrer">Developer Documents</a><br />🗣️ Join the conversation on the <a className="game7-page_game7-page__copy-link__RRbWL" href="https://discord.gg/g7dao" target="_blank" rel="noreferrer">Game7 Discord</a></p>
                    <hr className="game7-page_game7-page__line-on-surface__vwOLx" />
                    <h2>Challenges</h2>
                    <p className="game7-page_game7-page__copy-lead__4MicW">There will be <b>three challenges</b> offered, each targeting
                        game developers with different levels of familiarity with blockchain technology.<br /><br />Game7 has generously
                        sponsored <b>each</b> challenge with <b>$3,000 of prizes</b> on offer...</p>
                    <ul className="game7-page_game7-page__copy-list__OUWwB" style={{  }}>
                        <li>
                            <h3>🥇 1st Place: <span style={{ color: 'var(--primary-color)' }}>$1,500</span></h3>
                        </li>
                        <li>
                            <h3>🥈 2nd Place: <span style={{ color: 'var(--primary-color' }}>$900</span></h3>
                        </li>
                        <li>
                            <h3>🥉 3rd Place: <span style={{ color: 'var(--primary-color' }}>$600</span></h3>
                        </li>
                    </ul>
                    <p id="accordion-jump" className="game7-page_game7-page__copy-sub__12QTh">...for a total prize pool of <b>$9,000</b>. Good luck!</p>
                    <ul className="accordion_accordion__container__CW08e">
                        <li>
                            <h2 className="accordion-item_accordion-item__title__KCJXL"><button className="accordion-item_accordion-item__button__A6Agn">🖱️ MOUSE-ONLY MADNESS — for Blockchain
                                Newbies</button></h2>
                            <div className="accordion-item_accordion-item__container__uwHjA" style={{  }}>
                                <div className="accordion-item_accordion-item__content__am1_e">
                                    <div className="game7-page_game7-page__copy-sub__12QTh">
                                        <p><b>PROMPT</b></p>
                                        <hr className="game7-page_game7-page__line-primary__4RmAN" />
                                        <p><i>This challenge is meant for game devs with no previous blockchain
                                            knowledge.</i><br /><br />All input for the game you submit must be done with <b>mouse
                                                only</b>. This means you have a ton of flexibility for genres. For example, you could do
                                            an action game controlled with the mouse, an RPG where the mouse is used to select options
                                            from a menu, or even a light gun style game. It’s up to you!<br /><br />This game jam is
                                            solely
                                            focused on making the best game you can within the two-week period. The only time we’ll
                                            touch a blockchain is for team onboarding and entry submission.<br /><br />Of course, we do
                                            want
                                            to bring you into the Web3 world. Because our game jam will happen on-chain, you’ll need to
                                            set up a <b>MetaMask digital wallet</b> and provide your <b>public address</b>. Our team is
                                            standing by to help – please tag <b><span style={{ color: 'var(--primary-color)' }}>@</span>Final
                                                Boss | Rensa Games#9033</b> on Discord for assistance.<br /><br /><u>Coming Soon:</u>
                                            The
                                            link to the Challenge on Showcase will be listed here following deployment of smart
                                            contracts, tentatively scheduled for February 14.<br /><br /></p>
                                        <p className="game7-page_game7-page__copy-sub__12QTh"><b>JUDGING</b></p>
                                        <hr className="game7-page_game7-page__line-primary__4RmAN" />
                                        <ul style={{  }}>
                                            <li>Games will be judged on fit to the theme and quality/fun factor of the game. </li><br />
                                            <li>Submissions must be uploaded <b>through Showcase</b> on the Rensa Games platform. You
                                                can review the process here: <a className="game7-page_game7-page__copy-link__RRbWL" href="https://docs.rensa.games/showcase/building#submitting-an-entry" target="_blank" rel="noreferrer">Showcase</a>
                                            </li><br />
                                            <li>The game must be an <b>original game</b> made for the purpose of this game jam. <span style={{ color: 'var(--warning-color)' }}><b>Any games found to have been made prior to
                                                the jam will be disqualified.</b></span></li><br />
                                        </ul>
                                        <p><u>Coming Soon:</u> Our judges will include developers, content creators, and influencers.
                                            They will be announced through the Game7 Twitter <a className="game7-page_game7-page__copy-link__RRbWL" href="https://twitter.com/G7_DAO" target="_blank" rel="noreferrer">(@G7_DAO)</a> during the two-week registration period, so stay
                                            tuned.<br /><br /></p>
                                        <p className="game7-page_game7-page__copy-sub__12QTh"><b>TOOLS</b></p>
                                        <hr className="game7-page_game7-page__line-primary__4RmAN" />
                                        <ul style={{ }}>
                                            <li>Games can be developed in any engine that can <b>export to HTML</b>. This includes
                                                Unity, Godot, Lua, plain HTML / Javascript, and more. We recommend that games are no
                                                larger than <b>10MB</b>.</li><br />
                                            <li>You can use third-party assets that you have the <b>legal rights</b> to use, i.e.:
                                                publicly available or purchased assets. You must provide attribution if necessary. <span style={{ color: 'var(--warning-color)' }}><b>Use of licensed content that you do not own
                                                    will be grounds for automatic disqualification.</b></span></li><br />
                                            <li>You will need a MetaMask digital wallet to register, submit entries, and receive prizes.
                                                The MetaMask browser extension can be downloaded at <a className="game7-page_game7-page__copy-link__RRbWL" href="https://metamask.io" target="_blank" rel="noreferrer">https://metamask.io</a>.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <h2 className="accordion-item_accordion-item__title__KCJXL"><button className="accordion-item_accordion-item__button__A6Agn">🔗 WEB 2.5 GAMING — for Blockchain
                                Developers</button></h2>
                            <div className="accordion-item_accordion-item__container__uwHjA" style={{ }}>
                                <div className="accordion-item_accordion-item__content__am1_e">
                                    <div className="game7-page_game7-page__copy-sub__12QTh">
                                        <p><b>PROMPT</b></p>
                                        <hr className="game7-page_game7-page__line-primary__4RmAN" />
                                        <p><i>This challenge is meant for developers experienced with the blockchain/crypto
                                            space.</i><br /><br />We task builders to create a functional game featuring <b>at least
                                                one
                                                blockchain element</b>, used in a creative way. For example, this could include in-game
                                            NFTs as items, crypto currencies deployed for marketplaces, smart contracts with story
                                            implications, and more.<br /><br />Builders will be required to submit a <b>zip file
                                                containing
                                                a pre-compiled binary</b>, or a <b>link to a website</b> where the game is deployed.
                                            Additionally, we request that any blockchain functionality is deployed to either
                                            <b>Goerli</b> testnet (Ethereum) or <b>Mumbai</b> testnets (Polygon), and therefore will not
                                            have any speculative value outside of the competition.<br /><br />Don’t forget to build a
                                            fun
                                            game as well! Per our judging criteria below, your submission will be assessed on both its
                                            gameplay and its blockchain utilization.<br /><br />If this is your first time building a
                                            blockchain deployment, we’re here to help. Because our game jam will happen on-chain, you’ll
                                            need to set up a <b>MetaMask digital wallet</b> and provide your <b>public address</b>. Our
                                            team is standing by to assist – please tag <b><span style={{ color: 'var(--primary-color)' }}>@</span>Final Boss | Rensa Games#9033</b> on
                                            Discord.<br /><br /><u>Coming Soon:</u> The link to the Challenge on Showcase will be listed
                                            here following deployment of smart contracts, tentatively scheduled for February
                                            14.<br /><br />
                                        </p>
                                        <p className="game7-page_game7-page__copy-sub__12QTh"><b>JUDGING</b></p>
                                        <hr className="game7-page_game7-page__line-primary__4RmAN" />
                                        <ul style={{ listStyle: 'disc' }}>
                                            <li>Games will be judged on creative use of the blockchain element, execution/coding of the
                                                blockchain element, and quality/fun factor of the game. The game must <b>verifiably
                                                    demonstrate</b> the blockchain element (i.e. activity can be confirmed on the
                                                chain’s block explorer) to be considered.</li><br />
                                            <li>Submissions must be uploaded <b>through Showcase</b> on the Rensa Games platform. You
                                                can review the process here: <a className="game7-page_game7-page__copy-link__RRbWL" href="https://docs.rensa.games/showcase/building#submitting-an-entry" target="_blank" rel="noreferrer">Showcase</a>
                                            </li><br />
                                            <li>The game must be an <b>original game</b> made for the purpose of this game jam. <span style={{ color: 'var(--warning-color)' }}><b>Any games found to have been made prior to
                                                the jam will be disqualified.</b></span></li><br />
                                        </ul>
                                        <p><u>Coming Soon:</u> Our judges will include developers, content creators, and influencers.
                                            They will be announced through the Game7 Twitter <a className="game7-page_game7-page__copy-link__RRbWL" href="https://twitter.com/G7_DAO" target="_blank" rel="noreferrer">(@G7_DAO)</a> during the two-week registration period, so stay
                                            tuned.<br /><br /></p>
                                        <p className="game7-page_game7-page__copy-sub__12QTh"><b>TOOLS</b></p>
                                        <hr className="game7-page_game7-page__line-primary__4RmAN" />
                                        <ul style={{ listStyle: 'disc' }}>
                                            <li>You can use third-party assets that you have the <b>legal rights</b> to use, i.e.:
                                                publicly available or purchased assets. You must provide attribution if necessary. <span style={{ color: 'var(--warning-color)' }}><b>Use of licensed content that you do not own
                                                    will be grounds for automatic disqualification.</b></span></li><br />
                                            <li>You are allowed to utilize any open-source, turnkey deployments for blockchain
                                                technology, such as the SDKs offered by thirdweb.</li><br />
                                            <li>You will need a MetaMask digital wallet to register, submit entries, and receive prizes.
                                                The MetaMask browser extension can be downloaded at <a className="game7-page_game7-page__copy-link__RRbWL" href="https://metamask.io" target="_blank" rel="noreferrer">https://metamask.io</a>.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <h2 className="accordion-item_accordion-item__title__KCJXL"><button className="accordion-item_accordion-item__button__A6Agn">🔄 SWAP IT — for Blockchain
                                Experts</button></h2>
                            <div className="accordion-item_accordion-item__container__uwHjA" style={{  }}>
                                <div className="accordion-item_accordion-item__content__am1_e">
                                    <div className="game7-page_game7-page__copy-sub__12QTh">
                                        <p><b>PROMPT</b></p>
                                        <hr className="game7-page_game7-page__line-primary__4RmAN" />
                                        <p><i>This challenge is meant for Web3 developers working on complex topics such as
                                            interoperability.</i><br /><br />We task builders to create a game that best
                                            demonstrates
                                            <b>trading in-game items</b> represented by NFTs <b>across chains</b>. The chains you choose
                                            are up to you and your team.<br /><br />Builders will be required to submit a <b>zip file
                                                containing a pre-compiled binary</b>, or a <b>link to a website</b> where the game is
                                            deployed. Additionally, we request that any blockchain functionality is deployed to a
                                            <b>testnet</b> (or series of testnets) and therefore will not have any speculative value
                                            outside of the competition.<br /><br />Don’t forget to build a fun game as well! Per our
                                            judging
                                            criteria below, your submission will be assessed on both its gameplay and its blockchain
                                            utilization.<br /><br /><u>Coming Soon:</u> The link to the Challenge on Showcase will be
                                            listed
                                            here following deployment of smart contracts, tentatively scheduled for February
                                            14.<br /><br />
                                        </p>
                                        <p className="game7-page_game7-page__copy-sub__12QTh"><b>JUDGING</b></p>
                                        <hr className="game7-page_game7-page__line-primary__4RmAN" />
                                        <ul style={{ listStyle: 'disc' }}>
                                            <li>Games will be judged on quality and functionality of code for the interoperability
                                                element, and quality/fun factor of the game itself. The game must <b>verifiably
                                                    demonstrate</b> the ability to trade in-game items between different chains to be
                                                considered (i.e. activity can be confirmed on a block explorer). </li><br />
                                            <li> Submissions must be uploaded <b>through Showcase</b> on the Rensa Games platform. You
                                                can review the process here: <a className="game7-page_game7-page__copy-link__RRbWL" href="https://docs.rensa.games/showcase/building#submitting-an-entry" target="_blank" rel="noreferrer">Showcase</a>
                                            </li><br />
                                            <li>The game must be an <b>original game</b> made for the purpose of this game jam. <span style={{ color: 'var(--warning-color)' }}><b>Any games found to have been made prior to
                                                the jam will be disqualified.</b></span></li><br />
                                        </ul>
                                        <p><u>Coming Soon:</u> Our judges will include developers, content creators, and influencers.
                                            They will be announced through the Game7 Twitter <a className="game7-page_game7-page__copy-link__RRbWL" href="https://twitter.com/G7_DAO" target="_blank" rel="noreferrer">(@G7_DAO)</a> during the two-week registration period, so stay
                                            tuned.<br /></p><br />
                                        <p className="game7-page_game7-page__copy-sub__12QTh"><b>TOOLS</b></p>
                                        <hr className="game7-page_game7-page__line-primary__4RmAN" />
                                        <ul style={{ listStyle: 'disc' }}>
                                            <li>You can use third-party assets that you have the <b>legal rights</b> to use, i.e.:
                                                publicly available or purchased assets. You must provide attribution if necessary. <span style={{ color: 'var(--warning-color)' }}><b>Use of licensed content that you do not own
                                                    will be grounds for automatic disqualification.</b></span></li><br />
                                            <li>You are allowed to utilize any open-source, turnkey deployments for blockchain
                                                technology, such as the SDKs offered by thirdweb.</li><br />
                                            <li>You will need a MetaMask digital wallet to register, submit entries, and receive prizes.
                                                The MetaMask browser extension can be downloaded at <a className="game7-page_game7-page__copy-link__RRbWL" href="https://metamask.io" target="_blank" rel="noreferrer">https://metamask.io</a>.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <h2 className="accordion-item_accordion-item__title__KCJXL"><button className="accordion-item_accordion-item__button__A6Agn">❓ FAQs</button></h2>
                            <div className="accordion-item_accordion-item__container__uwHjA" style={{  }}>
                                <div className="accordion-item_accordion-item__content__am1_e">
                                    <div className="game7-page_game7-page__copy-sub__12QTh">
                                        <p><b>[Q]:</b> Do I need to own crypto to participate in this Challenge?</p>
                                        <hr style={{ borderColor: 'var(--background-color)' }} />
                                        <p><b>[A]:</b> Yes, <b>BUT</b>… You will only need enough MATIC on Polygon to pay for the gas
                                            required to perform a transaction. We will provide all registered participants with a
                                            <b>small amount</b> of MATIC to cover this while also confirming the appropriate wallet
                                            addresses.<br /><br />To learn more about gas fees, check out: <a className="game7-page_game7-page__copy-link__RRbWL" href="https://www.coindesk.com/learn/what-are-ethereum-gas-fees/" target="_blank" rel="noreferrer">https://www.coindesk.com/learn/what-are-ethereum-gas-fees/</a><br /><br />
                                        </p>
                                        <hr className="game7-page_game7-page__line-on-surface__vwOLx" />
                                        <p><br /><b>[Q]:</b> Can I submit multiple versions of my entry?</p>
                                        <hr style={{ borderColor: 'var(--background-color)' }} />
                                        <p><b>[A]:</b> Yes. Judges will be presented with only the <b>final</b> version you upload, so
                                            feel free to submit early and often!</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <hr className="game7-page_game7-page__line-on-surface__vwOLx" />
                    <h2>Timing</h2>
                    <ul className="game7-page_game7-page__copy-list__OUWwB">
                        <li><b>Pre-Registration</b>: Friday, February 3rd - Thursday, February 16th</li>
                        <li><b>Registration &amp; Building</b>: Friday, February 17th - Friday, March 10th</li>
                        <li><b>Judging</b>: Friday, March 10th - Friday, March 17th</li>
                    </ul>
                    <p className="game7-page_game7-page__copy-sub__12QTh">We expect to announce winners and distribute prizes immediately
                        after the judging phase on Friday, March 17th.</p>
                    <hr className="game7-page_game7-page__line-on-surface__vwOLx" />
                    <h2>Participation</h2>
                    <p className="game7-page_game7-page__copy-lead__4MicW">The Game7 game jam is a <b>fully remote event</b>. All are
                        welcome to participate, provided they follow the <i>Official Rules</i> and <i>Code of Conduct</i>.<br /><br />To
                        participate in the game jam, developers will need to <b>register on the Showcase platform using a MetaMask
                            digital wallet</b>, and submit their game by the deadline. You’re able to enter as an individual or a team
                        of <b>up to six</b> people.<br /><br />Submissions will be judged by a panel of experts, who will evaluate them
                        based on various criteria such as innovation, fit to prompt, and overall quality.<br /><br />The winners of each
                        challenge will be announced at the end of the game jam, and the prizes will be distributed to winners’ digital
                        wallets through the Showcase platform.</p>
                    <hr className="game7-page_game7-page__line-on-surface__vwOLx" />
                    <h2>See You There!</h2>
                    <p className="game7-page_game7-page__copy-lead__4MicW">Game7 and Rensa Games are committed to fostering a community of
                        game developers in the Web3 space, and we believe that this game jam is just the first step in that journey. So,
                        whether you're new to blockchain or an expert, we encourage you to register for the game jam and start
                        creating!<br /><br />Our teams are standing by to answer any questions you may have.<br /><br />If you're
                        looking for
                        some additional how-to's, Rensa's developer documentation is available to walk you through any part of the game
                        jam:</p>
                    <h4><a href="https://docs.rensa.games/showcase" className="game7-page_game7-page__copy-link__RRbWL" target="_blank" rel="noreferrer" id="terms">https://docs.rensa.games/showcase</a></h4>
                    <hr className="game7-page_game7-page__line-on-surface__vwOLx" />
                    <h3>Terms</h3>
                    <ul className="game7-page_game7-page__copy-sub__12QTh" style={{ listStyle: 'disc', maxWidth: '500px' }}>
                        <li>This game jam is sponsored and operated by Game7 DAO.</li><br />
                        <li>By participating in this game jam, you have accepted the <a className="game7-page_game7-page__copy-link__RRbWL" href="https://www.rensa.games/showcase/game7-game-jam/official-rules" rel="noreferrer" target="_blank">Official Rules</a> and <a className="game7-page_game7-page__copy-link__RRbWL" href="https://www.rensa.games/showcase/game7-game-jam/code-of-conduct" rel="noreferrer" target="_blank">Code of Conduct</a>.</li><br />
                        <li>Participants under the age of 17 must have a <i>Parental Consent Waiver</i> completed by their legal
                            guardian. That document can be completed via DocuSign <a className="game7-page_game7-page__copy-link__RRbWL" href="https://na4.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=da6fe2a0-c9d3-41af-b7ad-8dd36b44a6a1&env=na4&acct=77183b46-860f-4366-a14d-377795bafe26&v=2" target="_blank" rel="noreferrer">[here]</a></li><br />
                        <li>Game7 DAO will be able to promote the teams and their respective entries through its social media accounts.
                        </li><br />
                        <li>Rensa, Inc. will be able to promote the teams and their respective entries through the social media accounts
                            for its platform, Rensa Games.</li><br />
                        <li>Ownership of the code developed during the event is the property of the Participants.</li>
                    </ul>
                    <p><a className="game7-page_game7-page__copy-link__RRbWL" href="#top">Back to top</a></p>
                </main>

            </Main>

            <Footer alert={true} />
        </>
    );
};

export default Gamejam;
