import { useState } from "react";
// Imports
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";

// Pages
import Blog from "./pages/Blog.js";
import BlogDetail from "./pages/BlogDetail.js";
import Brand from "./pages/Brand.js";
import Cookies from "./pages/CookiePolicy.js";
import Error from "./pages/Error.js";
import Gamejam from "./pages/Gamejam.js";
import Home from "./pages/Home.js";
import Media from "./pages/Media.js";
import MediaDetail from "./pages/MediaDetail.js";
import Newsletter from "./pages/Newsletter.js";
import NewsletterDetail from "./pages/NewsletterDetail.js";
import Privacy from "./pages/PrivacyPolicy.js";
import Research from "./pages/Research.js";
import Terms from "./pages/TermsAndConditions.js";
import Start from "./pages/GetStarted.js";
import XPEvent from "./pages/3XP.js";

import { PressReleaseContext } from "./hooks/pressReleaseContext.js";

// Theme
import { light } from "./constants/themes.js";

function App() {
  const [release, setRelease] = useState();
  return (
    <PressReleaseContext.Provider value={{ release, setRelease }}>
      <ThemeProvider theme={light}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/dev-report-2022" element={<Research />} />
            <Route path="/cookies" element={<Cookies />} />
            <Route path="/get-started" element={<Start />} />
            <Route path="/3xp" element={<XPEvent />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/brand" element={<Brand />} />
            <Route path="/media" element={<Media />} />
            <Route path="/media/:id" element={<MediaDetail />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:id" element={<BlogDetail />} />
            <Route path="/newsletter" element={<Newsletter />} />
            <Route path="/gamejam" element={<Gamejam />} />
            <Route path="/newsletter/:id" element={<NewsletterDetail />} />
            <Route path="*" element={<Error />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </PressReleaseContext.Provider>
  );
}

export default App;
