import { createClient } from "contentful";
import moment from "moment";

const singlePressRelease = () => {
  const client = createClient({
    space: process.env.REACT_APP_SPACE_ID,
    accessToken: process.env.REACT_APP_ACCESS_TOKEN,
  });

  const getRelease = async ({ path }) => {
    try {
      const entries = await client.getEntries({
        content_type: "contentPressRelease",
      });

      const result = entries.items.map((e, i) => {
        if (path === e.fields.pressUrl) {
          const index = i;
          const title = e.fields.pressTitle;
          const subtitle = e.fields.pressSubtitle;
          const date = moment(e.fields.pressPublishDate).calendar();
          const description = e.fields.pressBody;
          const object = e;

          return { title, index, date, subtitle, description, object };
        }
        return null;
      });
      return result;
    } catch (error) {
      console.log(error);
    }
  };
  return { getRelease };
};

export default singlePressRelease;
