import styled from "styled-components";

export const Container = styled.div`
  background: white;
  border: 1px solid ${(props) => props.theme.border};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  transition: 750ms;
  overflow: hidden;
  justify-content: space-between;

  &:hover {
    transition: 750ms;
    cursor: pointer;
    box-shadow: 0px 12px 12px rgba(191, 179, 179, 0.15);
  }
`;

export const Image = styled.img`
  aspect-ratio: 16 / 9;
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.border};
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;
  background-image: url(${(props) => props.src});
`;

export const Top = styled.div`
  height: auto;
  width: auto;
`;

export const Content = styled.div`
  padding: 4px 20px 20px 20px;
  height: max-content;
`;

export const Button = styled.div`
  padding: 0px 20px 0px 20px;
  background: skyblue;
`;

export const Tags = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  grid-gap: 12px;
`;

export const Budget = styled.div`
  padding: 8px 12px;
  background: #ecfbf1;
  border: 1px solid #b3efc7;
  border-radius: 24px;
  color: #268046;
  font-size: 11px;
  font-weight: bold;
  font-family: "Inter", sans-serif;
  text-transform: uppercase;
  max-width: 100px;
  display: flex;
  justify-content: center;
`;

export const Status = styled.div`
  padding: 8px 12px;
  background: #e6f2fc;
  border: 1px solid #b3d9f5;
  border-radius: 24px;
  color: #01599b;
  font-size: 11px;
  font-weight: bold;
  font-family: "Inter", sans-serif;
  text-transform: uppercase;
  max-width: 60px;
  display: flex;
  justify-content: center;
`;

export const Title = styled.div`
  color: ${(props) => props.theme.primary};
  font-size: 32px;
  font-family: "Bebas Neue", sans-serif;
`;

export const Description = styled.div`
  color: ${(props) => props.theme.secondary};
  font-size: 16px;
  font-family: "inter", sans-serif;
  line-height: 1.5em;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const Route = styled.div`
  padding: 12px 24px;
  background: ${(props) => props.theme.accent};
  display: flex;
  border-radius: 4px;
  color: rgba(255, 255, 255, 0.5);
  border: none;
  font-size: 15px;
  margin-top: 24px;
  font-family: "Inter", sans-serif;
  justify-content: center;
  text-decoration: none;

  margin: 20px;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
    color: rgba(255, 255, 255, 0.5);
  }
`;

export const Disabled = styled.button`
  padding: 12px 24px;
  background: ${(props) => props.theme.accent};
  display: flex;
  border-radius: 4px;
  color: white;
  border: none;
  font-size: 15px;
  margin-top: 24px;
  font-family: "Inter", sans-serif;
  justify-content: center;
  text-decoration: none;

  margin: 20px;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
    color: rgba(255, 255, 255, 0.5);
  }
`;
