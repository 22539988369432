import { useEffect } from "react";
import Navigation from "../components/navigation";
import Hero from "../components/hero";
import Panel from "../components/panel";
import Join from "../components/join";
import Footer from "../components/footer";
import Alert from "../components/alert";

import events from "../assets/images/events.svg";
import grants from "../assets/images/grants.svg";
import investments from "../assets/images/investments.svg";
import product from "../assets/images/product.svg";
import community from "../assets/images/community.svg";
import meritImage from "../assets/images/merit.png";
import bordersImage from "../assets/images/borders.png";
import nationImage from "../assets/images/nation.png";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const line = (
    <div>
      We're not a niche. <br /> We're a nation.
    </div>
  );
  const nations = (
    <div>
      Borders divide.
      <br /> gaming unites.
    </div>
  );
  const merit = (
    <div>
      A nation governed <br /> by meritocracy.
    </div>
  );
  return (
    <>
      <Navigation />
      <Hero
        title="Welcome to game7"
        description="We believe that gaming has the power to improve the future of humanity by unlocking creative and financial opportunities for billions of people."
      />
      <Alert />
      <Panel
        link={""}
        src={nationImage}
        title={line}
        description="We are a nation representing over 3 billion gamers, immense economic value, and a universal culture that unites us all. "
      />
      <Panel
        link={"/investments"}
        reverse={true}
        src={bordersImage}
        title={nations}
        description="Nations don't need borders. They need people, economies, culture and governance. Our gaming nation brings together all those who are passionate about building a better future for gaming. "
      />
      <Panel
        link={"/"}
        src={meritImage}
        title={merit}
        description="We believe that a nation should be governed by those who make the greatest contributions, and it should be those contributions, not money, that grant power, status, influence and rewards."
      />
      <Join />
      <Footer alert={true} />
    </>
  );
};

export default Home;
