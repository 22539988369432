// https://github.com/contentful/rich-text/tree/master/packages/rich-text-react-renderer
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Navigation from "../components/navigation";
import Footer from "../components/footer";
import Hero from "../components/hero-content";
import TextColumn from "../components/textColumn/index.js";
import TextPage from "../components/textPage/index.js";
import TextPageLoading from "../components/textPageLoading";

import { options } from "../constants/richText.js";
import singleBlog from "../hooks/blog/singleBlog.js";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const BlogDetail = () => {
  const location = useLocation();
  const [blog, setBlog] = useState([]);
  const { getBlog } = singleBlog();
  const path = location.pathname.slice(6);

  useEffect(() => {
    getBlog({ path }).then((response) => setBlog(response));
    //eslint-disable-next-line
  }, [setBlog, path]);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const handleData = () => {
    return blog.map((b, i) => {
      if (b !== undefined && b !== null) {
        return (
          <>
            <Hero key={i} title={b.title} subtitle={b.subtitle} date={b.date} />
            <TextColumn>
              <TextPage
                key={i}
                content={documentToReactComponents(b.description, options)}
              />
            </TextColumn>
          </>
        );
      }
      return null;
    });
  };

  return (
    <>
      <Navigation />
      {blog.length > 0 ? handleData() : <TextPageLoading />}
      <Footer alert={true} />
    </>
  );
};

export default BlogDetail;
