// Relative Imports
import { Container, Brand, Icon, Name, Background, Join } from "./styles";
import logo from "../../assets/brand/logo.svg";
import icon from "../../assets/brand/icon.svg";
import brand from "../../assets/brand/brand.svg";

const Navigation = ({ hideButton }) => {
  return (
    <Container>
      <Brand to="/">
        <Background>
          <Icon src={icon} />
        </Background>
        <Name src={logo} />
      </Brand>
      {!hideButton && <Join to="/get-started">Join Now</Join>}
    </Container>
  );
};

export default Navigation;
