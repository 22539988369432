// Relative Imports
import {
  Container,
  Window,
  Row,
  Right,
  Left,
  Column,
  Title,
  Body,
  Link,
} from "./styles";
import Loading from "../loading/index.js";

const Modal = ({
  title,
  label,
  step,
  submit,
  increment,
  decrement,
  close,
  loading,
  disabled,
  children,
}) => {
  return (
    <Container>
      <Window>
        <Body>
          <Column>
            <Title>{title}</Title>
          </Column>
          {children}
        </Body>
        <Row>
          {step === 1 && (
            <>
              <Left onClick={close}>Close</Left>
              <Right disabled={disabled} onClick={submit}>
                {loading ? <Loading /> : "Submit"}
              </Right>
            </>
          )}
          {step === 2 && (
            <>
              <Left onClick={close}>Close</Left>
              <Link target="_blank" href="https://waitlist.game7.io">
                Join Waitlist
              </Link>
            </>
          )}
        </Row>
      </Window>
    </Container>
  );
};

export default Modal;
