import { useEffect, useState } from "react";
import moment from "moment";
import Navigation from "../components/navigation";
import Footer from "../components/footer";
import Hero from "../components/hero-content";
import TextColumn from "../components/textColumn";
import TextPage from "../components/textPage";
import TextPageLoading from "../components/textPageLoading";

// Contently
import { options } from "../constants/richText.js";
import useContentful from "../hooks/contentful.js";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const Terms = () => {
  const [legal, setLegal] = useState([]);
  const { getLegal } = useContentful();

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    getLegal().then((response) => setLegal(response));
    //eslint-disable-next-line
  }, []);

  const renderLegal = () => {
    return legal.map((b, i) => {
      // ID for the terms and conditions
      if (b.sys.id === "10maVbzCP9rIKcDbZfwLQE") {
        return (
          <>
            <Hero
              title={b.fields.legalTitle}
              subtitle={b.fields.legalSubtitle}
              date={moment(b.fields.legalPublishDate).calendar()}
            />
            <TextColumn>
              <TextPage
                key={i}
                content={documentToReactComponents(b.fields.legalBody, options)}
              />
            </TextColumn>
          </>
        );
      }
      return null;
    });
  };

  const handleData = () => {
    if (legal.length === 0) {
      return <TextPageLoading />;
    } else {
      return renderLegal();
    }
  };

  return (
    <>
      <Navigation />
      {handleData()}
      <Footer />
    </>
  );
};

export default Terms;
