import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Text = styled.div`
  max-width: 760px;
  height: auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
`;
