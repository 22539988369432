// Relative Imports
import { Container, Title, Description, Image } from "./styles";

const Confirmation = ({ referral }) => {
  return (
    <Container>
      <Image />
      <Title>Thank you!</Title>
      <Description>
        Your registration was received and we would like to invite you to join
        our Community Waitlist. You may use the following referral code to gain
        priority access: <strong>{referral}</strong>
      </Description>
    </Container>
  );
};

export default Confirmation;
