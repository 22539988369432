import { createClient } from "contentful";

const useContentful = () => {
  const client = createClient({
    space: process.env.REACT_APP_SPACE_ID,
    accessToken: process.env.REACT_APP_ACCESS_TOKEN,
  });

  const getLegal = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "contentLegal",
      });

      return entries.items;
    } catch (error) {
      console.log(error);
    }
  };
  return { getLegal };
};

export default useContentful;
