import styled from "styled-components";
import media from "./media.js";
// import { setLetterSpacing } from "../utils/utils.js";
// import { Link } from "react-router-dom";

export const Title = styled.div`
  font-family: "Agharti", sans-serif;
  text-transform: uppercase;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "120px")};
  color: ${(props) => props.theme.primary};
  line-height: 1.1em;
  max-width: ${(props) => props.maxWidth};
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};

  ${media.md`
    font-size: 72px;
  `}
`;

export const Description = styled.div`
  font-family: "Inter";
  font-size: 20px;
  line-height: 1.5em;
  color: ${(props) => props.theme.secondary};
  max-width: ${(props) => props.maxWidth};
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};
  ${media.md`
    font-size: 16px;
  `};
`;

export const H1 = styled.h1`
  font-family: "Agharti", sans-serif;
  text-transform: uppercase;
  font-size: 4em;
  color: ${(props) => props.theme.primary};
  line-height: 1.1em;
  font-weight: normal;
  margin: 0px;
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};
`;

export const H2 = styled.h2`
  font-family: "Agharti", sans-serif;
  text-transform: uppercase;
  font-size: 3em;
  color: ${(props) => props.theme.primary};
  line-height: 1.1em;
  font-weight: normal;
  margin: 0px;
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};
`;

export const H3 = styled.h3`
  font-family: "Agharti", sans-serif;
  text-transform: uppercase;
  font-size: 2.6em;
  color: ${(props) => props.theme.primary};
  line-height: 1.1em;
  font-weight: normal;
  margin: 0px;
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};
`;

export const H4 = styled.h4`
  font-family: "Agharti", sans-serif;
  text-transform: uppercase;
  font-size: 2em;
  color: ${(props) => props.theme.primary};
  line-height: 1.1em;
  font-weight: normal;
  margin: 0px;
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};
`;

export const H5 = styled.h5`
  font-family: "Agharti", sans-serif;
  text-transform: uppercase;
  font-size: 1.6em;
  color: ${(props) => props.theme.primary};
  line-height: 1.1em;
  font-weight: normal;
  margin: 0px;
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};
`;

export const H6 = styled.h6`
  font-family: "Agharti", sans-serif;
  text-transform: uppercase;
  font-size: 1.4em;
  color: ${(props) => props.theme.primary};
  line-height: 1.1em;
  font-weight: normal;
  margin: 0px;
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};
`;

export const P = styled.p`
  font-family: "Inter", sans-serif;
  font-size: 16px;
  color: ${(props) => props.theme.secondary};
  line-height: 1.5em;
`;

export const A = styled.a`
  font-family: "Inter", sans-serif;
  font-size: 16px;
  color: ${(props) => props.theme.accent};
  line-height: 1.5em;
`;

export const List = styled.li`
  font-family: "Inter", sans-serif;
  font-size: 16px;
  color: ${(props) => props.theme.secondary};
  line-height: 1.5em;
`;

export const LI = styled.li`
  font-family: "Inter", sans-serif;
  color: ${(props) => props.theme.secondary};
  line-height: 1.5em;
`;

export const UL = styled.ul`
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 1.5em;
  color: ${(props) => props.theme.secondary};
`;

export const OL = styled.ol`
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 1.5em;
  color: ${(props) => props.theme.secondary};
`;

export const Table = styled.table`
  font-family: "Inter", sans-serif;
  font-size: 16px;
  width: 100%;
  line-height: 1.5em;
  color: ${(props) => props.theme.secondary};
  border: 1px solid ${(props) => props.theme.border};
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 20px;

  ${media.sm`
    display: none;
  `}


  &:last-child {
      background: #f2f2f2;
    }
  }
`;

export const Content = styled.ul`
  font-family: "Inter", sans-serif;
  font-size: 16px;
  color: ${(props) => props.theme.secondary};
  line-height: 1.5em;
`;

export const Quote = styled.blockquote`
  color: ${(props) => props.theme.secondary};
  border-left: 2px solid ${(props) => props.theme.accent};
  padding-left: 24px;
  font-family: "Inter", sans-serif;
`;

export const Embeded = styled.img`
  height: auto;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 8px;
`;
