// Relative Imports
import { Container, Content, Inner } from "./styles";
import { ShimmerText, ShimmerTitle } from "react-shimmer-effects";

import Hero from "../_shimmers/hero-content/index.js";

const TextPageLoading = () => {
  return (
    <Container>
      <Hero />
      <Content>
        <Inner>
          <ShimmerTitle line={1} />
          <ShimmerText line={50} gap={10} />
        </Inner>
      </Content>
    </Container>
  );
};

export default TextPageLoading;
