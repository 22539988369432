// Relative Imports
import { Container, Title, Description, Fill } from "./styles";

const Partners = () => {
  return (
    <Container>
      <Title>Join Us.</Title>
      <Title>Fork the world</Title>
      <Description>
        Together we will build a future that is transparent, fair and
        collectively owned.
      </Description>
      <Fill to="/get-started">Join Us</Fill>
    </Container>
  );
};

export default Partners;
