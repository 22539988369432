import styled from "styled-components";
import media from "../../constants/media.js";

export const Container = styled.div`
  max-width: 1380px;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  margin-bottom: 80px;
  padding: 20px;

  ${media.md`
    grid-template-columns: 1fr;
  `}
`;

export const Section = styled.div`
  min-height: 400px;
  background: #fff;
  border: 1px solid ${(props) => props.theme.border};
  border-radius: 8px;
  padding: 20px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: flex-end;
  grid-gap: 12px;
`;

export const Image = styled.img`
  height: 200px;
  width: 100%;
  aspect-ratio: 16 / 9;
  margin-bottom: 20px;
`;

export const Disclaimer = styled.div`
  max-width: 1380px;
  padding: 20px;
  color: ${(props) => props.theme.secondary};
  font-size: 13px;
  line-height: 1.5em;
  margin: auto;
  margin-bottom: 80px;
`;
