import { createClient } from "contentful";

const pressReleases = () => {
  const client = createClient({
    space: process.env.REACT_APP_SPACE_ID,
    accessToken: process.env.REACT_APP_ACCESS_TOKEN,
  });

  const getPress = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "contentPressRelease",
      });

      return entries.items;
    } catch (error) {
      console.log(error);
    }
  };
  return { getPress };
};

export default pressReleases;
