// Relative Imports
import { Container, Section, Content, Image, Disclaimer } from "./styles";
import { H3, Description } from "../../constants/type.js";
import discord from "../../assets/images/discord.png";
import nft from "../../assets/images/nft.png";
import onboarding from "../../assets/images/onboarding.png";
import ButtonRow from "../buttonRow/index.js";

const Started = ({
  firstTitle,
  firstDesciption,
  secondTitle,
  secondDesciption,
  thirdTitle,
  thirdDesciption,
  disclaimer,
  button,
}) => {
  return (
    <>
      <Container>
        <Section>
          <Image src={discord} />
          <Content>
            <H3>{firstTitle}</H3>
            <Description>{firstDesciption}</Description>
          </Content>
        </Section>
        <Section>
          <Image src={onboarding} />
          <Content>
            <H3>{secondTitle}</H3>
            <Description>{secondDesciption}</Description>
          </Content>
        </Section>
        <Section>
          <Image src={nft} />
          <Content>
            <H3>{thirdTitle}</H3>
            <Description>{thirdDesciption}</Description>
          </Content>
        </Section>
      </Container>

      {disclaimer && (
        <>
          <ButtonRow />
          <Disclaimer>
            Disclaimer: Participation in the SteamDeck Giveaway at 3XP.gg
            constitutes acceptance of terms. Eligible winners will be announced
            on July 15, 2023 inside Discord. Participants must complete required
            steps as specified by later than July 11, 2023. Prize is a SteamDeck
            gaming device. Winner selection will be conducted randomly by Game7
            DAO Ltd ("Game7"). Winners will be notified in Discord and are
            required to claim the prize within 72 hours. Timely response and
            fulfillment of requirements are necessary. Prize delivery is free,
            but winners are responsible for taxes and customs fees. Game7 may
            use winners' name and entry content for promotional purposes.
            Participants release Game7 from liability. Game7 reserves the right
            to modify or terminate the Giveaway. Applicable laws apply.
          </Disclaimer>
        </>
      )}
    </>
  );
};

export default Started;
