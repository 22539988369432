import { useEffect, useState } from "react";
import moment from "moment";
import Navigation from "../components/navigation";
import Hero from "../components/hero-content";
import Footer from "../components/footer";
import Grid from "../components/grid";
import PressRelease from "../components/pressRelease";
import LoadingCard from "../components/loadingCard";
import Column from "../components/column";
import blogArticles from "../hooks/blog/blogArticles.js";

const Blog = () => {
  // Media Details
  const [blog, setBlog] = useState([]);
  const { getBlogs } = blogArticles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getBlogs().then((response) => setBlog(response));
    //eslint-disable-next-line
  }, []);

  const renderBlogs = () => {
    return blog.map((m, i) => {
      return (
        <PressRelease
          key={i}
          name={m.fields.blogTitle + " " + m.fields.blogSubtitle}
          date={moment(m.fields.blogPublishDate).calendar()}
          url={m.fields.blogUrl}
          button="Read Blog"
          img={m.fields.blogCoverImage.fields.file.url}
        />
      );
    });
  };

  return (
    <>
      <Navigation />
      <Hero title="Blog" />
      <Column>
        {blog.length === 0 && (
          <Grid>
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
          </Grid>
        )}
        <Grid>{renderBlogs()}</Grid>
      </Column>
      <Footer alert={true} />
    </>
  );
};

export default Blog;
