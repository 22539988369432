import styled from "styled-components";
import media from "../../constants/media.js";
import { Link } from "react-router-dom";

export const Container = styled.div`
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 80px 20px;
  margin-bottom: 100px;

  ${media.sm`
  padding: 20px 0px;
    `};
`;

export const Grid = styled.div`
  display: grid;
  grid-gap: 20px;
  background: pink;
  width: 1360px;
  grid-template-columns: repeat(5, 1fr);

  ${media.lg`
    grid-template-columns: repeat(4, 1fr);
  `};

  ${media.md`
    grid-template-columns: repeat(3, 1fr);
  `};

  ${media.sm`
    grid-template-columns: repeat(2, 1fr);
  `};
`;

export const Title = styled.div`
  font-family: "Agharti", sans-serif;
  text-transform: uppercase;
  font-size: 100px;
  color: ${(props) => props.theme.primary};
  line-height: 1.1em;
  margin-bottom: 12px;

  ${media.md`
    font-size: 72px;
    text-align: center;
  `}
`;

export const Description = styled.div`
  font-family: "Inter";
  font-size: 18px;
  line-height: 1.5em;
  margin-bottom: 20px;
  color: ${(props) => props.theme.secondary};
  max-width: 480px;
  text-align: center;

  ${media.md`
    font-size: 16px;
  `};
`;

export const Fill = styled(Link)`
  height: 60px;
  width: 200px;
  display: flex;
  font-family: "Inter", sans-serif;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: white;
  background: ${(props) => props.theme.accent};

  border-radius: 8px;
  text-decoration: none;
  transition: 750ms;

  &:hover {
    transition: 750ms;
    cursor: pointer;
  }
`;
