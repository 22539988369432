import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Navigation from "../components/navigation";
import Footer from "../components/footer";
import TextColumn from "../components/textColumn/index.js";
import Hero from "../components/hero-content";
import { options } from "../constants/richText.js";
import singleNewsletter from "../hooks/newsletter/singleNewsletter.js";

import TextPage from "../components/textPage/index.js";
import TextPageLoading from "../components/textPageLoading";

import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const NewsletterDetail = () => {
  const location = useLocation();
  const [newsletter, setNewsletter] = useState([]);
  const { getNewsletter } = singleNewsletter();

  const path = location.pathname.slice(12);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    getNewsletter({ path }).then((response) => setNewsletter(response));
    //eslint-disable-next-line
  }, []);

  const handleData = () => {
    return newsletter.map((b, i) => {
      if (b !== undefined && b !== null) {
        return (
          <>
            <Hero key={i} title={b.title} subtitle={b.subtitle} date={b.date} />
            <TextColumn>
              <TextPage
                key={i}
                content={documentToReactComponents(b.description, options)}
              />
            </TextColumn>
          </>
        );
      }
      return null;
    });
  };

  return (
    <>
      <Navigation />
      {newsletter.length > 0 ? handleData() : <TextPageLoading />}
      <Footer alert={true} />
    </>
  );
};

export default NewsletterDetail;
