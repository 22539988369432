import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  background: white;
  border: 1px solid ${(props) => props.theme.border};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  transition: 750ms;
  overflow: hidden;

  &:hover {
    transition: 750ms;
    cursor: pointer;
    box-shadow: 0px 12px 12px rgba(191, 179, 179, 0.15);
  }
`;

export const Image = styled.img`
  aspect-ratio: 16 / 9;
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.border};
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  background-image: url(${(props) => props.src});
`;

export const Top = styled.div`
  height: auto;
  width: auto;
`;

export const Inner = styled.div`
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Content = styled.div`
  height: max-content;
  padding-bottom: 20px;
`;

export const Title = styled.div`
  color: ${(props) => props.theme.primary};
  font-size: 32px;
  font-family: "Agharti", sans-serif;
  text-transform: uppercase;
`;

export const Description = styled.div`
  color: ${(props) => props.theme.secondary};
  font-size: 16px;
  font-family: "inter", sans-serif;
  line-height: 1.5em;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const Route = styled(Link)`
  padding: 12px 24px;
  background: ${(props) => props.theme.accent};
  display: flex;
  border-radius: 4px;
  color: white;
  border: none;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  justify-content: center;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
    color: rgba(255, 255, 255, 0.5);
  }
`;
