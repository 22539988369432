// Relative Imports
import { Container, Content } from "./styles";
import { ShimmerButton, ShimmerSectionHeader } from "react-shimmer-effects";
import hero from "../../../assets/patterns/hero.svg";

const HeroContent = () => {
  return (
    <Container backgroundImage={hero}>
      <Content>
        <ShimmerSectionHeader center />
        <ShimmerButton size="lg" />
      </Content>
    </Container>
  );
};

export default HeroContent;
