import styled from "styled-components";
import { Link } from "react-router-dom";
import media from "../../constants/media.js";
import bg from "../../assets/hero/bg-alt.png";

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-image: url(${bg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  ${media.md`
    background-image: url(${""});
    background-position: bottom;
    background-size: 100%, 25%;
    background-repeat: no-repeat, repeat;
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  z-index: 3000;
  max-width: 1360px;
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
  max-width: 1360px;

  ${media.md`
    margin-top: -10px;
    width: auto;
  `}
`;

export const Heading = styled.div`
  font-size: 30px;
  color: ${(props) => props.theme.secondary};
  font-family: "Bebas Neue", sans-serif;
  text-align: left;

  ${media.md`
    text-align: center;
  `}
`;

export const Title = styled.div`
  font-size: 120px;
  color: ${(props) => props.theme.primary};
  text-align: center;
  line-height: 1em;
  font-family: "Agharti", sans-serif;
  z-index: 2000;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 12px;

  ${media.md`
    font-size: 100px;
    text-align: center;
  `}

  ${media.sm`
    font-size: 80px;
    text-align: center;
  `}
`;

export const Description = styled.div`
  font-size: 20px;
  color: ${(props) => props.theme.secondary};
  max-width: 600px;
  line-height: 1.5em;
  font-family: "Inter", sans-serif;
  text-align: center;
  z-index: 2000;

  ${media.md`
    text-align: center;
    max-width: 680px;
    font-size: 18px;
  `}

  ${media.sm`
    text-align: center;
    max-width: 680px;
    font-size: 16px;
  `}
`;

export const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  grid-gap: 24px;
  margin-top: 20px;
  z-index: 2000;

  ${media.md`
    justify-content: center;
    width: 100%;
  `}
`;

export const Fill = styled(Link)`
  height: 60px;
  width: 200px;
  display: flex;
  font-family: "Inter", sans-serif;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: white;
  background: ${(props) => props.theme.accent};

  border-radius: 8px;
  text-decoration: none;
  transition: 750ms;

  ${media.md`
      width: 100%;
  `}

  &:hover {
    transition: 750ms;
  }
`;

export const Route = styled.a`
  font-size: 16px;
  color: #ff2401;
  transition: 750ms;
  text-align: left;
  text-decoration: none;
  margin-top: 16px;
  color: ${(props) => props.theme.secondary};
  transition: 750ms;

  ${media.md`
      text-align: center;
  `}

  &:hover {
    transition: 750ms;
  }
`;

export const Outline = styled(Link)`
  height: 60px;
  width: 200px;
  font-family: "Inter", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: ${(props) => props.theme.accent};
  border: 1px solid ${(props) => props.theme.accent};
  border-radius: 8px;
  text-decoration: none;
  transition: 750ms;

  ${media.md`
      width: 100%;
  `}

  &:hover {
    background: ${(props) => props.theme.accent};
    transition: 750ms;
    color: white;
  }
`;
