// Relative Imports
import { Container, Content, Route, Top } from "./styles";
import {
  ShimmerThumbnail,
  ShimmerTitle,
  ShimmerText,
} from "react-shimmer-effects";

const LoadingCard = ({
  name,
  date,
  description,
  disabled,
  height,
  url,
  img,
  button,
  onClick,
}) => {
  return (
    <Container>
      <Top>
        <ShimmerThumbnail height={184} />
        <Content>
          <ShimmerTitle line={2} gap={10} variant="primary" />
          <ShimmerText line={2} gap={10} />
        </Content>
      </Top>
      <Route>Loading...</Route>
    </Container>
  );
};

export default LoadingCard;
